import { PbIcon } from "@/components/pbIcon/PbIcon";
import { SvgIds } from "@/components/pbIcon/svg";
import { IconButton, Tooltip } from "@mui/material";

interface CmsTooltipProps {
  iconName: SvgIds;
  iconWidth: number;
  iconHeight: number;
  title: string;
}

export default function CmsTooltip(props: CmsTooltipProps) {
  return (
    <Tooltip
      className="cms-tooltip"
      title={
        <p className="title" style={{ color: "white" }}>
          {props.title}
        </p>
      }
    >
      <IconButton>
        <PbIcon
          name={props.iconName}
          width={props.iconWidth}
          height={props.iconHeight}
        />
      </IconButton>
    </Tooltip>
  );
}
