import useStrTypography from "@/hooks/useStrTypography";
import { storeColorCss } from "@/services/ceSettings/ceSettingsService";
import { globalConfig } from "@/services/globalConfig/globalConfigService";
import { getSearchSuggestions } from "@/services/search/searchService";
import { StoreSetting } from "@/types/ceSettings/ceSettings";
import { ResponsiveValues } from "@/types/globalConfig/globalConfig";
import { SEARCH_PAGE_URL } from "@/utils/constants";
import { InputAdornment } from "@mui/material";
import clsx from "clsx";
import { useTranslation } from "next-i18next";
import dynamic from "next/dynamic";
import { useRouter } from "next/router";
import { useEffect, useRef, useState } from "react";
import SearchBarStyle from "./searchBarStyle";

const SearchIcon = dynamic(() => import("@mui/icons-material/Search"));
const CloseIcon = dynamic(() => import("@mui/icons-material/Close"));
const OutlinedInput = dynamic(() => import("@mui/material/OutlinedInput"));
const IconButton = dynamic(() => import("@mui/material/IconButton"));

export interface SearchBarProps {
  onSubmit: () => void;
  toggleSearchBar: (toggle?: boolean) => void;
  asContentElement: boolean;
  searchPlaceholder?: string;
  cfgTypography?: StoreSetting;
  typographys?: StoreSetting;
  maxWidth?: ResponsiveValues;
  border?: String;
}
/**
 * SearchBar (in Navigation)
 * @param {*} props
 */
const SearchBar = (props: SearchBarProps) => {
  const [searchTerm, setSearchTerm] = useState("");
  const [suggestions, setSuggestions] = useState([]);
  const { t: tPublic } = useTranslation("public");
  const router = useRouter();

  const searchInput = useRef<any>({ value: null });
  const closeButton = useRef<any>(null);
  const searchButton = useRef<any>(null);

  useEffect(() => {
    setSearchTerm("");
  }, []);

  useEffect(() => {
    if (searchInput.current) {
      searchInput.current.value = searchTerm;
    }
    const getSearchSuggestionsWithDelay = async () => {
      if (searchTerm.length > 2) {
        const searchSuggestions = await getSearchSuggestions(
          encodeURIComponent(searchTerm)
        );
        if (searchSuggestions.success) {
          setSuggestions(searchSuggestions.response?.data);
        } else {
          global.log.warn("Couldn't fetch search-suggestions.");
          setSuggestions([]);
        }
      } else if (searchTerm.length < 1) {
        setSuggestions([]);
      }
    };

    const typePause = setTimeout(() => {
      getSearchSuggestionsWithDelay();
    }, 350);

    return () => {
      clearTimeout(typePause);
    };
  }, [searchTerm]);

  const { typographyClassName } = useStrTypography(
    props.asContentElement
      ? props.cfgTypography
      : globalConfig?.search?.searchBarTypography,
    props.asContentElement ? props.typographys : undefined
  );

  const triggerSearch = (selectedSuggestion: string) => {
    // Prevent searching for same searchTerm multiple times.
    if (selectedSuggestion !== router.query.q) {
      router.push(`${SEARCH_PAGE_URL}?q=${selectedSuggestion}`);
      setSearchTerm("");
      setSuggestions([]);
    }
    props.toggleSearchBar();
  };

  const onSelect = (selectedSuggestion: string) => {
    if (searchInput.current) {
      searchInput.current.value = selectedSuggestion;
      triggerSearch(selectedSuggestion);
    }
  };

  const onSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    if (searchInput.current && searchInput.current.value) {
      props.onSubmit();
      triggerSearch(searchInput.current.value);
    }
  };

  return (
    <>
      <div className={clsx("search-bar", typographyClassName)}>
        <form onSubmit={(e) => onSubmit(e)}>
          <div className="input-wrapper">
            <OutlinedInput
              aria-label={tPublic("search")}
              autoFocus
              fullWidth
              sx={{
                ".MuiInputBase-input": {
                  // Styled in searchBarStyle!
                },
              }}
              inputRef={searchInput}
              defaultValue={router.query.q}
              onChange={(e) => setSearchTerm(e.target.value)}
              onKeyUp={(e) => {
                e.key === "Escape" && props.toggleSearchBar();
              }}
              startAdornment={
                <div className="search-input-container">
                  <InputAdornment position="end">
                    {" "}
                    <IconButton
                      aria-label={tPublic("search")}
                      tabIndex={0}
                      ref={searchButton}
                      type="submit"
                    >
                      <SearchIcon
                        style={{
                          fill: `${storeColorCss(
                            globalConfig?.search?.searchBarSearchIconColor,
                            "var(--pb-black)"
                          )}`,
                        }}
                        fontSize="large"
                      />
                    </IconButton>
                  </InputAdornment>
                </div>
              }
              endAdornment={
                <div className="search-input-container">
                  <InputAdornment position="end">
                    {" "}
                    {!props.asContentElement && (
                      <IconButton
                        aria-label={tPublic("cancelSearch")}
                        onClick={() => props.toggleSearchBar()}
                        tabIndex={-1}
                        ref={closeButton}
                      >
                        <CloseIcon
                          style={{
                            fill: `${storeColorCss(
                              globalConfig?.search?.searchBarCloseIconColor,
                              "var(--pb-black)"
                            )}`,
                          }}
                          fontSize="large"
                        />
                      </IconButton>
                    )}
                  </InputAdornment>
                </div>
              }
              placeholder={
                props.asContentElement
                  ? props.searchPlaceholder
                  : globalConfig?.search?.searchBarPlaceholderText
              }
            />
            {suggestions.length > 0 ? (
              <div className="search-suggestions-wrapper">
                <ul
                  className="search-suggestions"
                  aria-label={tPublic("searchSuggestions")}
                >
                  {suggestions.map((suggestion, index) => {
                    return (
                      <li
                        key={index}
                        className="search-suggestion"
                        tabIndex={0}
                        onClick={() => onSelect(suggestion)}
                        onFocus={() => {
                          if (searchInput.current) {
                            searchInput.current.value = suggestion;
                          }
                        }}
                        onKeyUp={(e) => {
                          e.key === "Enter" && onSelect(suggestion);
                        }}
                      >
                        {suggestion}
                      </li>
                    );
                  })}
                </ul>
              </div>
            ) : null}
            {suggestions.length === 0 && searchTerm.length > 2 ? (
              <div className="no-result-wrapper">
                <ul
                  className="no-result"
                  aria-label={tPublic("noSearchSuggestions")}
                >
                  <li className="search-suggestion">
                    {`${tPublic("noSearchSuggestions")} "${searchTerm}"`}
                  </li>
                </ul>
              </div>
            ) : null}
          </div>
        </form>
      </div>
      <SearchBarStyle />
    </>
  );
};

export default SearchBar;
