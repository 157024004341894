import {
  ContentElementStoreSetting,
  StoreSetting,
} from "@/types/ceSettings/ceSettings";
import { STORE_COLOR_CLASS_PREFIX } from "@/utils/constants";
import { getEnforcedStoreValueId } from "@/utils/util";

export const CFG_CONTENT_ELEMENT_SETTING_NAME = "cfgStrContentElementSetting";

export let ceSettings: ContentElementStoreSetting[] | null = null;

/**
 * Sets the ceSettings variable above.
 * @param newCeSettings 
 */
export const setCeSettings = (newCeSettings: any) => {
  if (newCeSettings) {
    ceSettings = newCeSettings;
  } else {
    global.log.warn("fetched ceSettings is null or empty.");
  }
};

/**
 * getNestedCeSettingsData 
 * Returns the data of the nested CE-Store-Setting. (for example: headline in text-with-image, carousel ...)
 * @param ceSettings 
 * @param fallback 
 * @returns 
 */
export const getNestedCeSettingsData = (
  ceSettings?: StoreSetting,
  fallback: any = undefined
) => {
  if(!ceSettings) {
    return fallback;
  }
  const ceSettingsId = valueFromStoreSetting(ceSettings);
  return dataValueFromCeStoreSetting(ceSettings, ceSettingsId, fallback);
};

/**
 * determineStoreValueId
 * Returns the value from storeSetting and considers potential to be enforced storeValue-Ids.
 * @param storeSetting 
 * @param ceSettings 
 * @returns 
 */
export const determineStoreValueId = (storeSetting?: StoreSetting, ceSettings?: StoreSetting) => {
  let strId;
  if (ceSettings && storeSetting) {
    strId = getEnforcedStoreValueId(storeSetting, ceSettings);
  } else if (storeSetting) {
    strId = valueFromStoreSetting(storeSetting);
  }
  return strId;
};

/**
 * valueFromStoreSetting
 * Returns the number in the values-Array.
 * @param storeSetting 
 * @returns 
 */
export const valueFromStoreSetting = (
  storeSetting: StoreSetting
): number | undefined => {
  if (!storeSetting?.values || storeSetting.values.length === 0) {
    return undefined;
  }
  return storeSetting.values[0];
};

/**
 * dataValueFromCeStoreSetting
 * Returns the data of the ceStoreSetting parameter.
 * @param ceStoreSetting 
 * @param contentValue 
 * @param defaultValue 
 * @returns 
 */
export const dataValueFromCeStoreSetting = (
  ceStoreSetting: StoreSetting | undefined,
  contentValue: number | undefined,
  defaultValue: any
) => {
  if (!ceStoreSetting?.data || !contentValue) {
    return defaultValue;
  }
  return ceStoreSetting.data[contentValue] ?? defaultValue;
};

/**
 * dataValueFromStoreSetting
 * Returns the data object of the storeSetting parameter.
 * @param storeSetting 
 * @param defaultValue 
 * @returns 
 */
export const dataValueFromStoreSetting = (
  storeSetting: StoreSetting | undefined,
  defaultValue: any
) => {
  if (!storeSetting) {
    return defaultValue;
  }
  const storeValue = valueFromStoreSetting(storeSetting);
  if (!storeValue || !storeSetting.data) {
    return defaultValue;
  }
  return storeSetting.data[storeValue] ?? defaultValue;
};

/**
 * ceSettingsById
 * returns the ceSettings that have the id specific by the parameter
 * @param contentElementSettingId
 * @returns
 */
export const ceSettingById = (
  contentElementSettingId: number | undefined
): ContentElementStoreSetting | undefined => {
  if (!ceSettings || !contentElementSettingId) {
    return undefined;
  }
  return ceSettings?.find((setting) => setting.id === contentElementSettingId);
};

export const firstCeSettingByStoreType = (storeType: string) => {
  if (!ceSettings || !ceSettings.length) {
    return undefined;
  }
  return ceSettings.find((setting) => setting.type === storeType);
};

/**
 * allContentElementStoreSettingsByStoreType
 * returns all ceSettings of the given storeType
 * @param storeType 
 * @returns 
 */
export const allContentElementStoreSettingsByStoreType = (
  storeType: string
): ContentElementStoreSetting[] => {
  if (!ceSettings || !ceSettings.length) {
    return [];
  }
  const availableCeSettings = ceSettings?.filter(
    (setting) => setting.type === storeType
  );
  return availableCeSettings ?? [];
};

/**
 * compareStoreSettingValues
 * compares store setting values and returns true if they are equal.
 * @param source 
 * @param target 
 * @returns 
 */
export const compareStoreSettingValues = (
  source: number[],
  target: number[]
) => {
  for (const id of source) {
    if (!target.includes(id)) {
      return false;
    }
  }
  return true;
};

/**
 * checkPersistStoreSettingValues
 * @param sourceValues 
 * @param jsonStoreSettings 
 * @returns 
 */
export const checkPersistStoreSettingValues = (
  sourceValues: number[],
  jsonStoreSettings: StoreSetting | undefined
) => {
  const checkedValues: number[] = jsonStoreSettings?.data
    ? Object.entries(jsonStoreSettings.data)
        .map(([key, value]): number => {
          if (key !== "-1" && !value) {
            return 0;
          }
          return parseInt(key);
        })
        .filter((item) => item !== 0)
    : [];
  return compareStoreSettingValues(sourceValues, checkedValues);
};

/**
 * storeSettingByField
 * @param contentElementSetting 
 * @param settingsField 
 * @returns 
 */
export const storeSettingByField = (
  contentElementSetting: ContentElementStoreSetting | undefined | null,
  settingsField: string | undefined
): StoreSetting | undefined => {
  if (!contentElementSetting || !settingsField) {
    return undefined;
  }

  if (settingsField.includes(".")) {
    const firstPartIndex = settingsField.indexOf(".");
    const parentStoreSetting = storeSettingByField(
      contentElementSetting,
      settingsField.substring(0, firstPartIndex)
    );

    if (!parentStoreSetting) {
      return undefined;
    }

    const selectedContentElementSettingId =
      valueFromStoreSetting(parentStoreSetting);
    return storeSettingByField(
      ceSettingById(selectedContentElementSettingId),
      settingsField.substring(firstPartIndex + 1)
    );
  }

  return contentElementSetting.setting[settingsField];
};

/**
 * Pass storeSetting (and if you need a fallback like "var(--pb-black)" or "#000") and get a CSS-Color as return.
 * @param storeSetting
 * @param fallback (optional)
 * @returns
 */
export const storeColorCss = (
  storeSetting: StoreSetting | undefined,
  fallback: string | undefined = undefined
): string => {
  let color = fallback ?? "unset";
  if (storeSetting && valueFromStoreSetting(storeSetting)) {
    color = `var(--${STORE_COLOR_CLASS_PREFIX}${valueFromStoreSetting(
      storeSetting
    )})`;
  }
  return color;
};

export const storeLinkClassName = (storeSetting: StoreSetting | undefined) => {
  if (!storeSetting) {
    return undefined;
  }
  const linkId = valueFromStoreSetting(storeSetting);
  return `str-link-${linkId}`;
};
