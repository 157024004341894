import ResponseResult from "@/types/classes/ResponseResult";
import { StrapiLoginResponse } from "@/types/strapi";
import { getNextJsApiURL } from "@/utils/api";
import { axiosPostRequestClientSide } from "@/utils/axiosClientUtil";

export const authenticateCmsUserRequest = async (
  email: string,
  password: string
): Promise<ResponseResult<StrapiLoginResponse>> => {
  const authResult: ResponseResult<StrapiLoginResponse> =
    await axiosPostRequestClientSide(getNextJsApiURL("/cms/auth/login"), {
      email,
      password,
    });

  return authResult;
};

export const logoutCmsUserRequest = async (): Promise<ResponseResult<void>> => {
  const logoutResult: ResponseResult<void> = await axiosPostRequestClientSide(
    getNextJsApiURL("/cms/auth/logout"),
    {}
  );

  return logoutResult;
};

export const renewCmsUserTokenRequest = async (): Promise<
  ResponseResult<StrapiLoginResponse>
> => {
  const refreshResult: ResponseResult<StrapiLoginResponse> =
    await axiosPostRequestClientSide(
      getNextJsApiURL("/cms/auth/renew-token"),
      {}
    );

  return refreshResult;
};
