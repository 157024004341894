import PbCheckBox from "@/components/input/pbCheckbox/pbCheckbox";
import PbDropdown, {
  PbDropdownItem,
} from "@/components/input/pbDropdown/pbDropdown";
import PbInputControlled from "@/components/input/pbInputControlled/pbInputControlled";
import ModalInputWrapper from "@/components/util/modalInputWrapper";
import useCmsTranslation from "@/hooks/useCmsTranslation";
import { globalConfig } from "@/services/globalConfig/globalConfigService";
import { updateAttributeAction } from "@/store/slices/cmsEdit/cmsEditSlice";
import { useAppDispatch, useAppSelector } from "@/store/store";
import { DEFAULT_AS_SELECTABLE_VALUE } from "@/utils/constants";
import { isLocaleDefaultLocale } from "@/utils/localizationUtil";
import { isPageUrlRootUrl } from "@/utils/urlUtil";
import { isStoreValueDefaultAllowed } from "@/utils/util";
import { useRouter } from "next/router";

export default function CmsPageSettings() {
  const tCms = useCmsTranslation();
  const dispatch = useAppDispatch();
  const router = useRouter();
  const page = useAppSelector((state) =>
    state.cmsGeneral.editMode
      ? state.cmsEdit.data?.draftPage
      : state.cmsEdit.data
  );

  const editView = useAppSelector((state) => state.cmsGeneral.editView);

  const canEditUrl =
    !isPageUrlRootUrl(page?.url) && isLocaleDefaultLocale(router.locale!);

  const getAttributePath = (key: string): string => {
    return editView ? `draftPage.${key}` : `${key}`;
  };

  const getDefaultValue = (values: any) => {
    if (!values || (values && values.length === 0)) {
      return undefined;
    }
    if (isStoreValueDefaultAllowed(values)) {
      return -1 + "";
    }
    return values.at(0) + "";
  };

  const backgroundDropdownItems = () => {
    return globalConfig?.layout.bgImages?.data
      ? Object.entries(globalConfig?.layout.bgImages.data)
          .map(([key, value]): PbDropdownItem | undefined => {
            if (key === `${DEFAULT_AS_SELECTABLE_VALUE}`) {
              return {
                value: key,
                name: tCms("configModal-defaultValue"),
              };
            }
            if (!value) {
              return undefined;
            }
            return {
              value: key,
              name: value.strname,
            };
          })
          .filter((item) => item !== undefined)
          .map((item) => item as PbDropdownItem)
      : [];
  };

  const backgroundOptions = backgroundDropdownItems();

  return (
    <div className="cms-modal-input-wrapper-container">
      <ModalInputWrapper
        label={tCms("name")}
        description={tCms("page-typePageNameHere")}
      >
        <PbInputControlled
          label={""}
          value={page?.name}
          onChange={(e) => {
            dispatch(
              updateAttributeAction({
                attributePath: `${getAttributePath("name")}`,
                value: e.target.value,
              })
            );
          }}
          fullWidth
        />
      </ModalInputWrapper>
      {editView && (
        <ModalInputWrapper
          label={tCms("url")}
          description={tCms("page-addPageUrlHere")}
        >
          <PbInputControlled
            fullWidth
            label={""}
            disabled={!canEditUrl}
            value={page.url}
            type="text"
            onChange={(event) =>
              dispatch(
                updateAttributeAction({
                  attributePath: `${getAttributePath("url")}`,
                  value: event.target.value,
                })
              )
            }
          ></PbInputControlled>
        </ModalInputWrapper>
      )}
      <ModalInputWrapper
        label={tCms("page-disableNavigation")}
        description={tCms("page-selectDisabledNavigation")}
      >
        <PbCheckBox
          isSlider
          value={!page?.hasNavigation}
          onChange={(event) => {
            dispatch(
              updateAttributeAction({
                attributePath: `${getAttributePath("hasNavigation")}`,
                value: !event.target.checked,
              })
            );
          }}
          fullHeight
        />
      </ModalInputWrapper>
      <ModalInputWrapper
        label={tCms("page-disableFooter")}
        description={tCms("page-selectDisableFooter")}
      >
        <PbCheckBox
          isSlider
          value={!page?.hasFooter}
          onChange={(event) => {
            dispatch(
              updateAttributeAction({
                attributePath: `${getAttributePath("hasFooter")}`,
                value: !event.target.checked,
              })
            );
          }}
          fullHeight
        />
      </ModalInputWrapper>
      {globalConfig?.layout.bgImages &&
      globalConfig?.layout.bgImages.values.length > 1 ? (
        <ModalInputWrapper
          label={tCms("page-background")}
          description={tCms("page-selectBackground")}
        >
          <>
            <PbDropdown
              value={page?.strBgImage ? page?.strBgImage.values : undefined}
              onChange={(event) => {
                const selectedValue = parseInt(event.target.value);
                dispatch(
                  updateAttributeAction({
                    attributePath: `${getAttributePath("strBgImage")}`,
                    value: {
                      store: "str",
                      storeType: "background",
                      values: selectedValue ? [selectedValue] : [],
                    },
                  })
                );
              }}
              defaultValue={getDefaultValue(
                page?.strBgImage ? page?.strBgImage.values : undefined
              )}
              dropdownList={backgroundOptions}
              itemNameKey="name"
              itemValueKey="value"
            ></PbDropdown>
            {page?.strBgImage &&
            page.strBgImage.values &&
            page.strBgImage.values[0] &&
            page.strBgImage.values[0] != DEFAULT_AS_SELECTABLE_VALUE ? (
              <div
                className={`str-background-${
                  page?.strBgImage && page.strBgImage.values[0]
                    ? page.strBgImage.values[0]
                    : undefined
                }-preview`}
                style={{
                  marginTop: "1rem",
                  height: "200px",
                  backgroundRepeat: "no-repeat",
                  backgroundSize: "contain",
                }}
              ></div>
            ) : null}
          </>
        </ModalInputWrapper>
      ) : null}
    </div>
  );
}
