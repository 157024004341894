import {
  CFG_CONTENT_ELEMENT_SETTING_NAME,
  firstCeSettingByStoreType,
} from "@/services/ceSettings/ceSettingsService";
import {
  createEmptyContentElement,
  createEmptyNestedContentElement,
  createPageRequest,
  deletePageRequest,
  getCmsPageRequest,
  getDraftPageRequest,
  publishPageRequest,
  updateDraftPageRequest,
  updatePageSettingsRequest,
} from "@/services/pageService/pageService";
import { ReduxThunkAction } from "@/store/store";
import { StoreSetting } from "@/types/ceSettings/ceSettings";
import { CMS_PABU_VERSION } from "@/utils/constants";
import { deepImmutableCopy, getRandomSlug } from "@/utils/util";
import { setEditModeAction } from "../cmsGeneral/cmsGeneralSlice";
import {
  hideLoadingOverlayAction,
  showLoadingOverlayAction,
} from "../general/generalSlice";
import { addToArrayAction, initEditDataAction } from "./cmsEditSlice";

export const cmsGetDraftPageAndStartEditModeThunk =
  (url: string): ReduxThunkAction =>
  async (dispatch, getState) => {
    dispatch(showLoadingOverlayAction("loading draftpage..."));
    const cmsPageByUrlResult = await getCmsPageRequest(url);
    if (!cmsPageByUrlResult.success) {
      dispatch(hideLoadingOverlayAction());
      return Promise.reject();
    }

    const draftPageResult = await getDraftPageRequest(
      cmsPageByUrlResult.data.refId
    );

    dispatch(hideLoadingOverlayAction());
    if (draftPageResult.success) {
      dispatch(setEditModeAction(true));
      dispatch(initEditDataAction(draftPageResult.data));
      return Promise.resolve();
    }
    return Promise.reject();
  };

export const cmsSaveDraftPageContentThunk =
  (): ReduxThunkAction => async (dispatch, getState) => {
    dispatch(showLoadingOverlayAction("saving draftpage..."));
    const cmsSaveDraftPageResult = await updateDraftPageRequest(
      getState().cmsEdit.data?.draftPage?.id,
      getState().cmsEdit.data?.draftPage?.content
    );
    dispatch(hideLoadingOverlayAction());
    if (!cmsSaveDraftPageResult.success) {
      return Promise.reject();
    }
    return Promise.resolve();
  };

export const cmsPublishDraftPageContentThunk =
  (): ReduxThunkAction => async (dispatch, getState) => {
    dispatch(showLoadingOverlayAction("publishing page..."));
    const cmsSaveDraftPageResult = await publishPageRequest(
      getState().cmsEdit.data?.draftPage?.id
    );
    dispatch(hideLoadingOverlayAction());
    if (!cmsSaveDraftPageResult.success) {
      return Promise.reject();
    }
    return Promise.resolve();
  };

export const cmsCreatePageThunk =
  (): ReduxThunkAction => async (dispatch, getState) => {
    dispatch(showLoadingOverlayAction("creating page..."));
    const cmsCreatePageResult = await createPageRequest(
      getState().cmsEdit.data
    );
    dispatch(hideLoadingOverlayAction());
    if (!cmsCreatePageResult.success) {
      return Promise.reject();
    }
    return Promise.resolve(cmsCreatePageResult.data.page);
  };

export const cmsUpdatePageSettingsThunk =
  (): ReduxThunkAction => async (dispatch, getState) => {
    dispatch(showLoadingOverlayAction("updating page settings..."));
    const cmsUpdatePageSettingsResult = await updatePageSettingsRequest({
      ...getState().cmsEdit.data?.draftPage,
    });
    dispatch(hideLoadingOverlayAction());
    return !cmsUpdatePageSettingsResult.success
      ? Promise.reject()
      : Promise.resolve(cmsUpdatePageSettingsResult.data);
  };

export const cmsPageDeleteThunk =
  (): ReduxThunkAction => async (dispatch, getState) => {
    dispatch(showLoadingOverlayAction("deleting page settings..."));
    const cmsPageDeleteResult = await deletePageRequest(
      getState().cmsEdit.data.draftPage.id
    );
    const cmsPageRefDeleteResult = await deletePageRequest(
      getState().cmsEdit.data.draftPage.refId
    );
    dispatch(hideLoadingOverlayAction());
    return !cmsPageDeleteResult && !cmsPageRefDeleteResult
      ? Promise.reject()
      : Promise.resolve();
  };

export const cmsCloneContentElementThunk =
  (position: number): ReduxThunkAction =>
  async (dispatch, getState) => {
    const elementToClone = getState().cmsEdit.data.draftPage.content[position];
    const clonedElement = deepImmutableCopy(elementToClone);
    delete clonedElement.id;
    clonedElement.__new_id = `new_${getRandomSlug()}`;
    dispatch(
      addToArrayAction({
        attributePath: "draftPage.content",
        value: clonedElement,
        pos: position + 1,
      })
    );
  };

export const cmsAddContentElementThunk =
  (element: any, position?: number | undefined): ReduxThunkAction =>
  async (dispatch, getState) => {
    const newContentElement = createEmptyContentElement(element);
    if (newContentElement) {
      if (newContentElement.hasOwnProperty(CFG_CONTENT_ELEMENT_SETTING_NAME)) {
        const ceSettingInput = newContentElement[
          CFG_CONTENT_ELEMENT_SETTING_NAME
        ] as StoreSetting;
        const defaultCeSetting = firstCeSettingByStoreType(
          ceSettingInput.storeType
        );
        newContentElement[CFG_CONTENT_ELEMENT_SETTING_NAME] = {
          ...ceSettingInput,
          values: defaultCeSetting ? [defaultCeSetting.id] : [],
        } as StoreSetting;
      }

      if (
        newContentElement.hasOwnProperty("cfgInfo") &&
        !newContentElement.cfgInfo
      ) {
        // PaBu specific informations about the CE.
        newContentElement.cfgInfo = {
          // (custom) CreatedAt
          created: new Date().toISOString(),
          // CMS PABU Version
          version: CMS_PABU_VERSION,
        };
      }
    }
    dispatch(
      addToArrayAction({
        attributePath: "draftPage.content",
        value: newContentElement,
        pos: position,
      })
    );
  };

export const cmsAddNestedContentElementThunk =
  (
    nestedElement: any,
    position: number,
    nestedFieldName: string,
    nestedPosition?: number | undefined
  ): ReduxThunkAction =>
  async (dispatch, getState) => {
    const newNestedContentElement =
      createEmptyNestedContentElement(nestedElement);

    dispatch(
      addToArrayAction({
        attributePath: `draftPage.content[${position}].${nestedFieldName}`,
        value: newNestedContentElement,
        pos: nestedPosition,
      })
    );
  };
