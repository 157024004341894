import { cmsTranslate } from "@/services/cmsTranslation/cmsTranslationService";
import {
  authenticateCmsUserRequest,
  logoutCmsUserRequest,
  renewCmsUserTokenRequest,
} from "@/services/cmsUserService/cmsUserService";
import { updateCmsUserSettings } from "@/services/cmsUserSettings/cmsUserSettingsService";
import { createAppAsyncThunk } from "@/store/createAppAsyncThunk";
import { ReduxThunkAction } from "@/store/store";
import {
  hideLoadingOverlayAction,
  showLoadingOverlayAction,
} from "../general/generalSlice";
import { logoutAction } from "./cmsUserSlice";

export const authenticateCmsUserThunk = createAppAsyncThunk(
  "cmsUser/authenticateCmsUserThunk",
  async (
    {
      email,
      password,
    }: {
      email: string;
      password: string;
    },
    thunkAPI
  ) => {
    const authResult = await authenticateCmsUserRequest(email, password);
    if (authResult.success) {
      return authResult.data;
    }

    return thunkAPI.rejectWithValue(authResult.error?.message);
  }
);

export const logoutCmsUserThunk =
  (): ReduxThunkAction => async (dispatch, getState) => {
    dispatch(showLoadingOverlayAction("logging out..."));
    const logoutResult = await logoutCmsUserRequest();
    dispatch(hideLoadingOverlayAction());
    if (logoutResult.success) {
      dispatch(logoutAction());
      return Promise.resolve();
    }
    return Promise.reject();
  };

export const refreshCmsUserTokenThunk = createAppAsyncThunk(
  "cmsUser/refreshCmsUserTokenThunk",
  async (_, thunkAPI) => {
    const refreshResult = await renewCmsUserTokenRequest();
    if (refreshResult.success) {
      return refreshResult.data;
    }
    return thunkAPI.rejectWithValue(refreshResult.error?.message);
  }
);

export const updateCmsUserSettingsThunk =
  (): ReduxThunkAction => async (dispatch, getState) => {
    dispatch(showLoadingOverlayAction(cmsTranslate("cmsUser-updateSettings")));
    const cmsUserSettingsResult = await updateCmsUserSettings(
      getState().cmsUser.cmsUserSettings
    );
    dispatch(hideLoadingOverlayAction());
    if (!cmsUserSettingsResult.success) {
      return Promise.reject();
    }
    return Promise.resolve(cmsUserSettingsResult.data.page);
  };
