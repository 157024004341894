import PbGenericModal from "@/components/genericModal/pbGenericModal";
import useCmsTranslation from "@/hooks/useCmsTranslation";
import { updateCmsUserSettingsThunk } from "@/store/slices/cmsUser/cmsUserThunks";
import { useAppDispatch, useAppSelector } from "@/store/store";
import { createToastError, createToastSuccess } from "@/utils/utilComponents";
import CloseIcon from "@mui/icons-material/Close";
import { TabContext, TabList, TabPanel } from "@mui/lab";
import {
  DialogContent,
  IconButton,
  Tab,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { useState } from "react";
import CmsTabs from "../cmsTabs/cmsTabs";
import CmsUserSettings from "./cmsUserSettings";

interface CmsUserSettingsModalProps {
  open: boolean;
  onClose: () => void;
}
export default function CmsUserSettingsModal(props: CmsUserSettingsModalProps) {
  const tCms = useCmsTranslation();
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("md"));
  const [tabValue, setTabValue] = useState("0");
  const dispatch = useAppDispatch();
  const user = useAppSelector((state) => state.cmsUser.user.email);

  const sideMenu = useAppSelector(
    (state) => state.cmsUser.cmsUserSettings?.sideMenu
  );

  const handleChange = async () => {
    try {
      const updateUserSettings = await dispatch(updateCmsUserSettingsThunk());
      createToastSuccess(tCms("cmsUser-updateSuccess"));
      props.onClose();
    } catch (error) {
      createToastError(tCms("cmsUser-updateError"));
    }
  };

  const handleModalCloseEvent = () => {
    props.onClose();
  };

  return (
    <PbGenericModal
      className="cms-user-settings-modal cms-modal with-tabs"
      open={props.open}
      title={tCms("user-settingsUser") + `${user}`}
      muiDialogProps={{ fullWidth: true }}
      onConfirmClick={handleChange}
      onCancelClick={() => handleModalCloseEvent()}
      muiDialogContentSxProps={{
        padding: 0,
      }}
      // fullScreen={fullScreen}
    >
      <IconButton
        className="modal-close"
        aria-label="close"
        onClick={handleModalCloseEvent}
      >
        <CloseIcon />
      </IconButton>
      <DialogContent>
        <CmsTabs>
          <TabContext value={tabValue}>
            <TabList className="tab-list" aria-label={tCms("selectTab")}>
              <Tab label={tCms("options")} value="0" />
            </TabList>
            <TabPanel value="0">
              <CmsUserSettings />
            </TabPanel>
          </TabContext>
        </CmsTabs>
      </DialogContent>
    </PbGenericModal>
  );
}
